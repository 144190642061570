import ImageWrapper from "@/components/shared/imageWrapper/imageWrapper";
import imageLoader from "@/libs/helpers/imageLoader";
import dynamic from "next/dynamic";
import React, { useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useGlobalContext } from "@/libs/context/GlobalContext";
import { debounce, formatDateWithDate } from "@/libs/helpers/common";
import MagnifyingGlass from "../magnifyingGlass/MagnifyingGlass";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { IProjectDetails } from "@/interfaces/IHomeContent";
import { API } from "@/libs/helpers/http-client";
import { useRouter } from "next/compat/router";


type props = {
  projectData: any;
  slideData: any;
  setImageData?: Function;
  currentSlide: number;
  activeLowerBox: number;
  setActiveLowerBox: Function;
};
const Editor = dynamic(
  () => import("@/components/home/editor/Editor").then((mod) => mod.Editor),
  {
    ssr: false,
  }
);
export function Content({
  projectData,
  slideData,
  setImageData,
  currentSlide,
  setActiveLowerBox,
  activeLowerBox,
}: props) {
  const {
    props: {
      startZooming,
      zoomState,
      pdfZoomState,
      setPdfZoomState,
      editorZoomState,
      setEditorZoomState,
      selectedZoomTarget,
      transformComponentRef,
      pdfTransformComponentRef,
      module,
      setLoading
    },
  } = useGlobalContext();
  const { isMagnifying, setIsMagnifying, currentTool,data } = useCanvasContext();
  const [isExcluded, setIsExcluded] = useState(false);
  const [key, setKey] = useState(false);
  const router = useRouter();
  const roomId = router?.query.room;
  const isDownloadPdfPage = router?.pathname.includes('/downloadpdf');

  const excludeSlide = async (
    slideId:string,
  ) => {
      try {
        setLoading(true)
        const response: any = await API.post(`/api/updateSlideStatus`, {
          id: data.id,
          slideId: slideId,
        });
        if (response && response.data) {
          
          console.log("Slide Excluded Successfully");
          // Update the imageData state with the prev property
          setImageData!((prev: any) =>
            prev.map((item: any) => {
              if (item.slideData.slideId == slideId) {
                return {
                  ...item,
                  slideData: {
                    ...item.slideData,
                    isVisible: isExcluded,
                  },
                };
              }
              return item;
            })
          );
          setLoading(false)
          
        } 
      } catch (error) {
        setLoading(false)
        console.error("Error excluding slide:", error);
      }
  };

  const handleCheckboxChange = () => {
    setIsExcluded(!isExcluded);
    excludeSlide(slideData.slideId )
  };

  useEffect(()=>{
    setIsExcluded(!slideData.isVisible)
  },[slideData])

  const [currentEditorScale, setCurrentEditorScale] = useState({
    scale: editorZoomState.scale,
    positionX: editorZoomState.positionX,
    positionY: editorZoomState.positionY,
  });

  const [currentPdfScale, setCurrentPdfScale] = useState({
    scale: pdfZoomState.scale,
    positionX: pdfZoomState.positionX,
    positionY: pdfZoomState.positionY,
  });

  useEffect(() => {
    if (editorZoomState.scale !== currentEditorScale) {
      setCurrentEditorScale({
        scale: editorZoomState.scale,
        positionX: editorZoomState.positionX,
        positionY: editorZoomState.positionY,
      });
    }
    if (pdfZoomState.scale !== currentPdfScale) {
      setCurrentPdfScale({
        scale: pdfZoomState.scale,
        positionX: pdfZoomState.positionX,
        positionY: pdfZoomState.positionY,
      });
    }
  }, [editorZoomState.scale, pdfZoomState.scale, slideData.slideId]);

  // const [key, setKey] = useState(0);

  useEffect(() => {
    // setKey(prev => prev + 1);
    setKey(true);
  }, [currentEditorScale, currentPdfScale]);

  useEffect(() => {
    // setKey(0);
    setKey(false);
    setCurrentEditorScale({ scale: 1, positionX: 0, positionY: 0 });
    setCurrentPdfScale({ scale: 1, positionX: 0, positionY: 0 });
  }, [slideData.slideId]);

  const getContent = () => {
    if (slideData.type.includes("Materials")) {
      return (
        <div className="incoSvenmainbox mb16">
          {slideData.data.map((f: any, idx: number) => (
            <div className="incoSveninerrbox mb10" key={idx}>
              <h3 className="text000 font12 mb10">{f.category}</h3>
              <div className="incoSeninermain">
                {f.data.map((m: any, idx: number) => (
                  <div className="incoIntiles" key={idx}>
                    <div className="inPdintextbox">
                      {/* <h4 className="font12 text000 mb10">{m.type}</h4> */}
                      {m.attributes.map((v: any, idx: any) => (
                        <div className="inBasicdetext" key={idx}>
                          <span className="widTh100">{v.key}</span>
                          <p>{`: ${v.value}`}</p>
                        </div>
                      ))}
                    </div>
                    <div className="inPdsvimgnox">
                      <span className="font10 text000 mb5 dispBlok">
                        {m.colorId}
                      </span>
                      {m.image && (
                        <ImageWrapper
                          src={m.image}
                          alt=""
                          width={80}
                          height={80}
                          loader={imageLoader}
                          fallbackSrc={`/assets/images/error.svg`}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (slideData.type.includes("Wall Elevations")) {
      return (
        <div className="incoTwopdmain mb16">
          <div className="incopdfImbox ">
            {slideData.data.map((f: any, idx: number) => (
              <div className="inpdfInimgb" key={idx}>
                <span className="font10 text000 mb10 dispBlok">{f.name}</span>
                <figure>
                  <ImageWrapper
                    src={`${f.image}`}
                    alt=""
                    width={510}
                    height={255}
                    loader={imageLoader}
                    fallbackSrc={`/assets/images/error.svg`}
                  />
                </figure>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (
      slideData.type.includes("Plan View") ||
      slideData.type.includes("Top View") ||
      slideData.type.includes("Cabinet Views") ||
      slideData.type.includes("Elevations") ||
      slideData.type.includes("Top 3D View")
    ) {
      return (
        <div className="incoPdthrmbox mb16">
          {slideData.data.name && (
            <div
              className="viewTitle"
              style={{
                height: "40px",
                backgroundColor: "#DDF8FE",
                width: "100%",
              }}
            ></div>
          )}
          <div className="incoodTinbox">
            {slideData.data.image && (
              <figure>
                <ImageWrapper
                  loader={imageLoader}
                  fill
                  src={`${slideData.data.image}`}
                  alt=""
                  className="img-responsive"
                  fallbackSrc={`/assets/images/error.svg`}
                />
              </figure>
            )}
            {slideData.data.table && (
              <div className="table-container">
                <table>
                  <tbody>
                    {slideData.data.table.slice(0, 5).map((data: any) => (
                      <tr key={data.id}>
                        <td>
                          <figure>
                            <ImageWrapper
                              loader={imageLoader}
                              width={50}
                              height={50}
                              src={`${data.path}`}
                              alt=""
                              className="img-responsive"
                              fallbackSrc={`/assets/images/error.svg`}
                            />
                          </figure>
                        </td>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {slideData.data.table.length > 5 && (
                  <table>
                    <tbody>
                      {slideData.data.table.slice(5, 10).map((data: any) => (
                        <tr key={data.id}>
                          <td>
                            <figure>
                              <ImageWrapper
                                loader={imageLoader}
                                width={50}
                                height={50}
                                src={`${data.path}`}
                                alt=""
                                className="img-responsive"
                                fallbackSrc={`/assets/images/error.svg`}
                              />
                            </figure>
                          </td>
                          <td>{data.id}</td>
                          <td>{data.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else if (slideData.type.includes("Floorplan")) {
      return (
        <div className="incoPdthrmbox mb16">
          {/* {slideData.data.name && <div className='viewTitle'></div>} */}
          <div className="incoodFloor">
            <figure>
              <ImageWrapper
                loader={imageLoader}
                fill
                src={`${slideData.data.image}`}
                alt=""
                className="img-responsive"
                fallbackSrc={`/assets/images/error.svg`}
              />
            </figure>
          </div>
        </div>
      );
    } else if (slideData.type.includes("Cabinet List")) {
      return (
        <div className="incoSixmBox mb16">
          <div className="incoPdsixHead">
            <span className="font10 text222">Image</span>
            <span className="font10 text222">Material Specs</span>
            <span className="font10 text222">Accessories</span>
            <span className="font10 text222">Exposed Sides</span>
            <span className="font10 text222">Instructions</span>
          </div>
          <div className="incoSixmainubox">
            <div className="incoCsixcamBox">
              {slideData?.data?.map((f: any, idx: number) => (
                <div className="incoCsixcabtiles" key={idx}>
                  <div className="incoCsixinntiles">
                    <div className="imgtexbox">
                      <ImageWrapper
                        className="img-responsive"
                        width={40}
                        height={40}
                        src={f.image}
                        loader={imageLoader}
                        alt=""
                        fallbackSrc={`/assets/images/error.svg`}
                      />
                      <span className="interPdftexsp text666">
                        POS No. <span className="text000">{f?.position}</span>
                      </span>
                    </div>
                    <div className="innerTextbox">
                      <span className="ineerTexboxan">{f.name}</span>
                      <span className="ineerTexboxan">{f.description}</span>
                      <span className="ineerTexboxan">
                        <span className="text666">SKU ID</span> : {f.SKUID}
                      </span>
                      <span className="ineerTexboxan">{f.dimension}</span>
                    </div>
                  </div>
                  <div className="incoRgtxttile">
                    <div
                      className="innerTextrgtbox"
                      style={{ alignItems: "center" }}
                    >
                      <span style={{ fontSize: "10px" }}>
                        CarcassMaterial :
                      </span>{" "}
                      <p>{f.carcassMaterial}</p>
                    </div>
                    {f.carcassFinish && (
                      <div
                        className="innerTextrgtbox"
                        style={{ alignItems: "center" }}
                      >
                        <span style={{ fontSize: "10px" }}>
                          CarcassFinish :
                        </span>{" "}
                        <p>{f.carcassFinish}</p>
                      </div>
                    )}
                    {f.materialSpecs && f.materialSpecs.length > 0 && (
                      <div
                        className="innerTextrgtbox innerSpecs"
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                          fontSize: "10px",
                          gap: "3px",
                          flexDirection: "column",
                        }}
                        key={idx}
                      >
                        {f.materialSpecs.map((mat: any, index: number) => (
                          <div key={index}>
                            {mat.attributes &&
                              mat.attributes.map((n: any, idx2: number) => (
                                <div key={idx2} style={{ display: "flex" }}>
                                  <div style={{ width: "81px" }}>{n.key} :</div>
                                  <p>{n.value}</p>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {f.accessories && f.accessories.length > 0 ? (
                    <div className="accpdfTextiles">
                      {f.accessories.map((exp: any, index: number) => (
                        <div key={index}>
                          {exp.attributes &&
                            exp.attributes.length > 0 &&
                            exp.attributes.map((atr: any, index: number) => (
                              <div className="accpdgInnweText" key={index}>
                                <p> {atr.value}</p>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="accpdfTextiles font10">NA</div>
                  )}
                  {f.exposedSides && f.exposedSides.length > 0 ? (
                    <div className="accpdfTextiles">
                      {f.exposedSides.map((exp: any, ixd: number) => (
                        <div key={ixd}>
                          {exp.name && (
                            <div className="font10 f600 mb5 text000">
                              {exp.name} :
                            </div>
                          )}
                          {exp.attributes &&
                            exp.attributes.length > 0 &&
                            exp.attributes.map((atr: any, index: number) => (
                              <div className="accpdgInnweText" key={index}>
                                <p> {atr.value ? atr.value : "NA"}</p>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="accpdfTextiles font10">NA</div>
                  )}
                  {f.instructions && f.instructions.length > 0 ? (
                    <div className="accpdfTextiles">
                      {f.instructions.map((exp: any, xd: number) => (
                        <div key={xd}>
                          {exp.name && (
                            <div className="font10 f600 mb5 text000">
                              {exp.name} :
                            </div>
                          )}
                          {exp.attributes &&
                            exp.attributes.length > 0 &&
                            exp.attributes.map((atr: any, index: number) => (
                              <div className="accpdgInnweText" key={index}>
                                <p> {atr.value}</p>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="accpdfTextiles font10">NA</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (slideData.type.includes("Accessory List")) {
      return (
        <div className="incoEpdfmianbox mb16">
          <div className="inEigtaccmainbox">
            {slideData.data.map((f: any, idx: number) => (
              <div className="inEigaccinertiles" key={idx}>
                <span className="font10 text222 mb10 dispBlok">
                  {f.pos && (
                    <span
                      className="font12"
                      style={{
                        backgroundColor: "#daf4ff",
                        padding: "3px",
                        marginRight: "3px",
                      }}
                    >
                      {f.pos}{" "}
                    </span>
                  )}
                  {f.name}
                </span>
                <div className="incoInpdimgtexbo">
                  <div className="incoEightimgbox">
                    <ImageWrapper
                      width={60}
                      height={60}
                      src={f.image}
                      alt=""
                      fallbackSrc={`/assets/images/error.svg`}
                    />
                  </div>
                  <div className="incoEigttextbox">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <span>POS</span>
                          </th>
                          <th>
                            <span>SKU Code</span>
                          </th>
                          <th>
                            <span>Location</span>
                          </th>
                          <th>
                            <span>Qty</span>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {f.details.map((m: any, idx: number) => (
                          <tr key={idx}>
                            <td>
                              <span>{m.pos}</span>
                            </td>
                            <td>
                              <span>{m.SKUCode}</span>
                            </td>
                            <td>
                              <span>{m.location}</span>{" "}
                            </td>
                            <td>
                              <span>{m.qty}</span>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (slideData.type.includes("Wall 3D Views")) {
      return (
        <div className="incoEpdfmianbox mb16">
          <div className="inEigtaccmainbox">
            {slideData.data.map((f: any, idx: number) => (
              <div className="inEigaccinertile" key={idx}>
                <span className="font10 text222 mb10 dispBlok">{f.name}</span>
                <div className="incoInpdimgtexbo">
                  {/* <div className="incoEightimgbox"> */}
                  <ImageWrapper
                    width={490}
                    height={250}
                    src={`${f.image}`}
                    alt=""
                    loader={imageLoader}
                    fallbackSrc={`/assets/images/error.svg`}
                  />
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (slideData.type.includes("Custom")) {
      return (
        <div className="incoEpdfmianbox mb16">
          <div className="inEigtaccmainbox"></div>
        </div>
      );
    } else
      return (
        <div className="incoFistseconbox">
          <figure>
            <ImageWrapper
              fill
              className="img-responsive"
              src="/assets/images/firstpdfimg.png"
              alt=""
              loader={imageLoader}
              fallbackSrc={`/assets/images/error.svg`}
            />
          </figure>
          <div className="incopdIntertextbox">
            {projectData?.data?.clientName && (
              <h3 className="font16 textfff f600 mb5 dispBlok">
                {projectData?.data?.clientName}
              </h3>
            )}
            {projectData?.data?.roomName && (
              <div className="pdgFinertext">
                <span className="font14 textfff">ROOM NAME</span>
                <span className="font14 textfff widTh200 lineHeight16 capital">
                  : {projectData?.data?.roomName}
                </span>
              </div>
            )}
            {projectData?.data?.roomType && (
              <div className="pdgFinertext">
                <span className="font14 textfff">ROOM TYPE</span>
                <span className="font14 textfff widTh200 lineHeight16">
                  : {projectData?.data.roomType}
                </span>
              </div>
            )}
            {projectData?.data?.designedBy && (
              <div className="pdgFinertext">
                <span className="font14 textfff">DESIGNER NAME</span>
                <span className="font14 textfff widTh200">
                  : {projectData?.data?.designedBy}
                </span>
              </div>
            )}
            {projectData?.data?.productName && (
              <div className="pdgFinertext">
                <span className="font14 textfff">INCO ID</span>
                <span className="font14 textfff widTh200">
                  : INC0{projectData?.data?.productName}
                </span>
              </div>
            )}
            {projectData?.data?.roomId && (
              <div className="pdgFinertext">
                <span className="font14 textfff">ROOM ID</span>
                <span className="font14 textfff widTh200">
                  : {projectData?.data?.roomId}
                </span>
              </div>
            )}
            {projectData?.data?.address && (
              <div className="pdgFinertext">
                <span className="font14 textfff">CITY</span>
                <span className="font14 textfff widTh200">
                  : {projectData?.data?.address}
                </span>
              </div>
            )}
            {projectData?.data?.date && (
              <div className="pdgFinertext">
                <span className="font14 textfff">DATE</span>
                <span className="font14 textfff widTh200">
                  : {formatDateWithDate(projectData?.data?.date)}
                </span>
              </div>
            )}
            {projectData?.data?.clientAddress && (
              <div className="pdgFinertext">
                <span className="font14 textfff">CLIENT ADDRESS</span>
                <span className="font14 textfff widTh200 lineHeight16">
                  : {projectData?.data?.clientAddress}
                </span>
              </div>
            )}
          </div>
        </div>
      );
  };

  // const calculateNewScale = (currentScale: number, transformScale: number) => {
  //     return currentScale * transformScale;
  // };

  // let diff = editorZoomState.data.scale - pdfZoomState.scale;
  // let diff2 = pdfZoomState.scale - editorZoomState.data.scale;
  return (
    <div className="incopdf2Minbox" id="incopdf2Minbox">
      <div className="incopdfInerm">
        {slideData.type != "Project Detail" && (
          <div className="incoHeadm mb12 dFlex alignCenter justBet">
            <div>
                <span className="font12 text666 f600">{slideData.type}</span>
                {(slideData.type.includes("Plan View") ||
                    slideData.type.includes("Floorplan") ||
                    slideData.type.includes("Top View") ||
                    slideData.type.includes("Cabinet Views") ||
                    slideData.type.includes("Elevations") ||
                    slideData.type.includes("Top 3D View")) && (
                    <span className="font12 text222 f600">
                        {" "}
                        | {slideData.data.name}
                    </span>
                )}
            </div>
            {!slideData.type.includes("Custom") && !isDownloadPdfPage && <div className="slideExclude">
                <label className="dFlex alignCenter">
                    <input
                    type="checkbox"
                    checked={isExcluded}
                    onChange={handleCheckboxChange}
                    />
                    Exclude
                </label>
            </div>}
        </div>
        )}
              <div style={{position:"relative"}}>

                  <div
                      style={{
                          border: startZooming ? "1.5px solid #17a8e5" : "none",
                          // padding: startZooming ? "5px" : '0',
                          cursor: startZooming
                              ? "url('/assets/images/zoom-cursor.svg'), auto"
                              : currentTool
                                  ? "crosshair"
                                  : "auto",
                      }}
                  >
                      <MagnifyingGlass isMagnifying={isMagnifying}>
                          <div
                              style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  // ...(selectedZoomTarget.editor ? { zIndex: 1 } : {}),
                                  ...(selectedZoomTarget.editor ? { zIndex: 1 } : {}),
                                  ...(!startZooming ? { zIndex: 1 } : {}),
                              }}
                          >
                              {key && (
                                  <TransformWrapper
                                      disabled={
                                          !startZooming || currentTool != "" || selectedZoomTarget.pdf
                                      }
                                      // disabled={(!startZooming || currentTool != ''   )}

                                      doubleClick={{ disabled: true }}
                                      minScale={1}
                                      maxScale={4}
                                      initialScale={currentEditorScale.scale}
                                      initialPositionX={currentEditorScale.positionX}
                                      initialPositionY={currentEditorScale.positionY}
                                      ref={transformComponentRef}
                                      onTransformed={debounce((e: any) => {
                                          const newScale = e.state.scale;
                                          setEditorZoomState({
                                              scale: newScale,
                                              positionX: e.state.positionX,
                                              positionY: e.state.positionY,
                                              id: slideData.slideId + "_editor",
                                              tool: "zoomEditor",
                                              lastScale: newScale,
                                              points: [],
                                          });

                                          // if (selectedZoomTarget.pdf) {
                                          //     setPdfZoomState((prevState: any) => ({
                                          //         ...prevState,
                                          //         scale: Math.max(newScale - Math.max(diff, diff2), 1) > 2 ? 2 : Math.max(newScale - Math.max(diff, diff2), 1),
                                          //         positionX: e.state.positionX,
                                          //         positionY: e.state.positionY,
                                          //         id: slideData.slideId + '_pdf',
                                          //         lastScale: Math.max(newScale - Math.max(diff, diff2), 1) > 2 ? 2 : Math.max(newScale - Math.max(diff, diff2), 1),
                                          //         tool: "zoomPdf",
                                          //         points: [],
                                          //     }));
                                          // }
                                      }, 100)}
                                  >
                                      <>
                                          <TransformComponent
                                              contentStyle={{
                                                  transform: `translate(${editorZoomState.id === slideData.slideId + "_editor"
                                                          ? editorZoomState.positionX &&
                                                          editorZoomState.positionX
                                                          : 0
                                                      }px, ${editorZoomState.id === slideData.slideId + "_editor"
                                                          ? editorZoomState.positionY &&
                                                          editorZoomState.positionY
                                                          : 0
                                                      }px) scale(${editorZoomState.id === slideData.slideId + "_editor"
                                                          ? editorZoomState.scale && editorZoomState.scale
                                                          : 1
                                                      })`,
                                                  transformOrigin: "0% 0%",
                                              }}
                                          >
                                              <Editor
                                                  slideId={slideData.slideId}
                                                  activeLowerBox={activeLowerBox}
                                                  setActiveLowerBox={setActiveLowerBox}
                                                  parent={slideData.type.replace(" Cont", "")}
                                              />
                                          </TransformComponent>
                                      </>
                                  </TransformWrapper>
                              )}
                          </div>
                          {/* <div style={selectedZoomTarget.editor && !startZooming ? { opacity: 1 } : { opacity: 0.8 }}> */}
                          <div
                              style={
                                  selectedZoomTarget.pdf && !startZooming
                                      ? { opacity: 1 }
                                      : { opacity: 0.8 }
                              }
                          >
                              {key && (
                                  <TransformWrapper
                                      minScale={1}
                                      maxScale={4}
                                      disabled={
                                          !startZooming ||
                                          currentTool != "" ||
                                          selectedZoomTarget.editor
                                      }
                                      initialPositionX={currentPdfScale.positionX}
                                      initialPositionY={currentPdfScale.positionY}
                                      initialScale={currentPdfScale.scale}
                                      ref={pdfTransformComponentRef}
                                      doubleClick={{ disabled: true }}
                                      onTransformed={debounce((e: any) => {
                                          const newScale = e.state.scale;
                                          setPdfZoomState({
                                              scale: newScale,
                                              positionX: e.state.positionX,
                                              positionY: e.state.positionY == 8 ? 0 : e.state.positionY ,
                                              id: slideData.slideId + "_pdf",
                                              tool: "zoomPdf",
                                              lastScale: newScale,
                                              points: [],
                                          });
                                      }, 100)}
                                  >
                                      <TransformComponent
                                          contentStyle={{
                                              transform: `translate(${pdfZoomState.id === slideData.slideId + "_pdf"
                                                      ? pdfZoomState.positionX && pdfZoomState.positionX
                                                      : 0
                                                  }px, ${pdfZoomState.id === slideData.slideId + "_pdf"
                                                      ? pdfZoomState.positionY && pdfZoomState.positionY
                                                      : 0
                                                  }px) scale(${pdfZoomState.id === slideData.slideId + "_pdf"
                                                      ? pdfZoomState.scale && pdfZoomState.scale
                                                      : 1
                                                  })`,
                                              transformOrigin: "0% 0%",
                                          }}
                                      >
                                          <div id="pdfEditorBox">{getContent()}</div>
                                      </TransformComponent>
                                  </TransformWrapper>
                              )}
                          </div>
                      </MagnifyingGlass>
                  </div>
                  {isExcluded && !slideData.type.includes("Custom") && <div className="watermark">
                      This Slide is excluded from Docket
                  </div>
                  }
              </div>

        {slideData.type != "Project Detail" && (
          <div className="incoPlowehwd">
            <div className="incoLftlowe">
              <div className="incoLfttiles">
                <figure>
                  <ImageWrapper
                    loader={imageLoader}
                    src="/assets/images/Inco-logo-small.svg"
                    alt=""
                    width={60}
                    height={30}
                    fallbackSrc={`/assets/images/error.svg`}
                  />
                </figure>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Room ID</span>
                <span className="font12 dispBlok text222">
                  {projectData?.data?.roomId}
                </span>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Room Name</span>
                <span className="font12 dispBlok text222">
                  {projectData?.data?.roomName}
                </span>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Inco Id</span>
                <span className="font12 dispBlok text222">
                  {projectData?.data?.productName}
                </span>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Date</span>
                <span className="font12 dispBlok text222">
                  {" "}
                  {formatDateWithDate(projectData?.data?.date)}
                </span>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Designed By</span>
                <span className="font12 dispBlok text222">
                  {projectData?.data?.designedBy}
                </span>
              </div>
              <div className="incoLfttiles">
                <span className="font10 dispBlok text666">Company Name</span>
                <span className="font12 dispBlok text222">
                  {projectData?.data?.company}
                </span>
              </div>
            </div>
            <div className="incopdRgtl">
              <span className="incopdrifSp font10 text000 dispBlok">
                {currentSlide <= 9 ? `0${currentSlide}` : currentSlide}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
