import React, { useState } from "react";
import styles from "./StoreProducts.module.css";
import Content from "../content/Content";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Image from 'next/image';
import { useCanvasContext } from '@/libs/context/CanvasContext'
import MagnifyingGlass from "@/components/home/magnifyingGlass/MagnifyingGlass";

type props = {
  slideContent: any
  activeLowerBox: any;
  setActiveLowerBox: Function,
  data: any;
  refss: any;
}

const Editor = dynamic(
  () => import("@/components/home/editor/Editor").then((mod) => mod.Editor),
  {
    ssr: false,
  }
);

export default function StoreProducts({ slideContent, activeLowerBox, setActiveLowerBox, data, refss }: props) {
  const router = useRouter();
  const roomId = router.query.room
  const projectId = router.query.project
  const { isMagnifying, setIsMagnifying } = useCanvasContext()

  const [defaultSlideId, setDefaultSlideId] = useState<any>(`StoreProducts_${roomId}_${projectId}`)

  return ((data?.mainJsonData && data.mainJsonData.length) ?
    <div className={`${styles.storeWrapper} pdfSectionBox`} id="pdfSectionBox">
      {/* <TransformComponent> */}
      <MagnifyingGlass isMagnifying={isMagnifying}>
        <div style={{ margin: "0 auto", width: "fit-content" }}>
          <Editor
            slideId={defaultSlideId}
            activeLowerBox={activeLowerBox}
            setActiveLowerBox={setActiveLowerBox}
            parent={defaultSlideId}
          />
          <Content mainJsonData={data?.mainJsonData} />
        </div></MagnifyingGlass>
      {/* </TransformComponent> */}
    </div>
    : (
      // <TransformComponent>
      <MagnifyingGlass isMagnifying={isMagnifying}>
        <div className={styles.noData}>
      <figure>
        <Image height={300} width={300} src="/assets/images/noData.svg" alt='No data'></Image>
      </figure>
      <div className={styles.msg}>No Product found</div>
      </div>
      </MagnifyingGlass>
      // </TransformComponent>
    )
  )
}
