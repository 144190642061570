import React from 'react'
import styles from './Modular.module.css'
import { Content } from '../home/content'
import {
  IProjectDetails
} from "@/interfaces/IHomeContent";

type props = {
  slideContent: any
  activeLowerBox: any;
  setActiveLowerBox: Function,
  projectData: IProjectDetails;
  currentSlide: any;
  refss: any;
  setImageData?:Function;
}



export default function Modular({ slideContent,projectData, activeLowerBox, setActiveLowerBox, currentSlide, refss,setImageData }: props) {

  return (
    <div className={`${styles.modularWrapper} pdfSectionBox`} id="pdfSectionBox">
      <div style={{ width: "70.157rem", height: "49.6rem" }} ref={refss} >
        <Content
          projectData={projectData}
          slideData={slideContent}
          currentSlide={currentSlide}
          activeLowerBox={activeLowerBox}
          setActiveLowerBox={setActiveLowerBox}
          setImageData={setImageData}
        />
      </div>
    </div >
  )
}
