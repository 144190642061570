import React, { useRef, useEffect, useState } from 'react';
import styles from './MagnifyingGlass.module.css';

interface MagnifierProps {
    children: React.ReactNode;
    scale?: number;
    size?: number;
    isMagnifying: Boolean | any;
}

const MagnifyingGlass = ({ children, scale = 2, size = 250, isMagnifying }: MagnifierProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const handleRef = useRef<HTMLDivElement>(null);
    const glassRef = useRef<HTMLDivElement>(null);
    const cloneRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.documentElement.style.setProperty('--scale', scale.toString());
        document.documentElement.style.setProperty('--size', `${size}px`);
    }, [scale, size]);

    const moveMagnifyingGlass = (event: React.PointerEvent<HTMLDivElement>) => {
        if (handleRef.current && glassRef.current && cloneRef.current) {
            const rect = contentRef.current?.getBoundingClientRect();
            if (!rect) return;
            const pointerX = event.clientX - rect.left;
            const pointerY = event.clientY - rect.top;
            const contentScale = contentRef.current ? contentRef.current.getBoundingClientRect().width / contentRef.current.offsetWidth : 1;
            handleRef.current.style.left = `${(pointerX / contentScale - size / 2) + 60}px`;
            handleRef.current.style.top = `${(pointerY / contentScale - size / 2) - 30}px`;
            if (cloneRef.current.children[0]) {
                const offsetX = (size * Math.pow(scale, 2)) / 2 - pointerX / contentScale * scale;
                const offsetY = (size * Math.pow(scale, 2)) / 2 - pointerY / contentScale * scale;
                (cloneRef.current.children[0] as HTMLElement).style.left = `${offsetX}px`;
                (cloneRef.current.children[0] as HTMLElement).style.top = `${offsetY - 30}px`;
            }
        }
    };

    const handleMouseEnter = () => {
        if (isMagnifying && contentRef.current && handleRef.current && glassRef.current && cloneRef.current) {
            setIsVisible(true);
            if (!cloneRef.current.children.length) {
                const clone = contentRef.current.cloneNode(true) as HTMLDivElement;
                clone.classList.add(styles.bodyClone);
                clone.style.top = '0px';
                clone.style.left = '0px';
                cloneRef.current.appendChild(clone);
            }
        }
    };

    const handleMouseLeave = () => {
        if (cloneRef.current) {
            cloneRef.current.innerHTML = '';
        }
        setIsVisible(false);
    };

    return (
        <div
            className={styles.wrapper}
            ref={contentRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onPointerMove={isMagnifying && isVisible ? moveMagnifyingGlass : undefined}
        >
            {children}
            {isMagnifying && (
                <div
                    ref={handleRef}
                    className={`${styles.handle} ${isVisible ? styles.visible : styles.hidden}`}
                >
                    <div
                        ref={glassRef}
                        className={styles.magnifyingGlass}
                        style={{
                            top: `${size / 10.2}px`,
                            left: `${size / 10.2}px`,
                        }}
                    >
                        <div ref={cloneRef}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MagnifyingGlass;