import React, { useState, useEffect } from "react";
import Image, { ImageProps } from "next/image";
import imageLoader from "@/libs/helpers/imageLoader";
type ImageWithFallbackProps = ImageProps & {
    fallbackSrc: string;
};

export default function ImageWrapper({
    alt = '',
    src,
    fallbackSrc,
    ...props
}: ImageWithFallbackProps) {
    const [error, setError] = useState(false);
    useEffect(() => {
        setError(false); // Reset error state when src changes
    }, [src]);

    const handleError = () => {
        setError(true);
    };

    return (
        <Image
            src={error ? fallbackSrc : (src || fallbackSrc)}
            alt={alt}
            {...props}
            quality={80}
            loader={imageLoader}
            onError={handleError}
        />
    );
}