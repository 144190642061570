import React, { useEffect, useState } from 'react'
import styles from './Content.module.css'

type props = {
  mainJsonData: any
}
export default function Content({ mainJsonData }: props) {
  const [subTotal, setSubTotal]=useState<number>(0)

  useEffect(()=>{

    let totalPrice =0;
    mainJsonData.forEach((item:any) => {
      totalPrice += +item.totalPrice;
    });
    setSubTotal(totalPrice)

  })

  return (
    <section className={styles.boqSection} id='pdfEditorBox'>

      <div className={styles.containerBqc}>
        <div className={styles.roomsSection}>
          <div className={styles.productTile}>
            <div className={styles.title}>Store Products</div>
            <div className={styles.productSection}>
              <table width="100%">
                <tbody>
                  <tr className={styles.productHeading}>

                    <th className="font14 text222 f500 textCenter">Image</th>
                    <th className="font14 text222 f500 ">Item</th>
                    <th colSpan={2} className="font14 text222 f500">Description</th>
                    <th className="font14 text222 f500">Qty</th>
                    <th className="font14 text222 f500">Unit Price</th>
                    <th className="font14 text222 f500 textRight">Amount</th>
                  </tr>
                  {mainJsonData && mainJsonData.map((data: any, index: number) => (<tr key={index} className={`${styles.productDetail} borderBottom`}>
                    <td className="textCenter">
                      <figure>
                        <img src={data.imagePath ? data.imagePath : "/assets/images/default.png"} alt="image" />

                      </figure>
                    </td>
                    <td className="font14 text222 f500">{data.name}</td>
                    <td colSpan={2} title={data.description} className="font14 text222 f500">{data.description.length > 150 ? `${data.description.substring(0, 150)}...` : data.description}</td>
                    <td className="font14 text222 f500">{data.quantity}</td>
                    <td className="font14 text222 f500 ">₹ {data.price}</td>
                    <td className="font14 text222 f500 textRight">₹ {data.totalPrice}</td>
                  </tr>))}
                </tbody>
              </table>
            </div>
          </div>
          <ul className={`${styles.totalAmount} mb16`}>
            <li>Sub Total</li>
            <li><span>₹ {subTotal}</span><em>*All prices are inclusive of GST</em></li>
          </ul>
        </div>
      </div>

    </section>

  )
}
